export function getYearMonthDifference(targetDate) {
  const startDate = new Date("2021-01-18");
  const endDate = new Date(targetDate);

  const startYear = startDate.getFullYear();
  const startMonth = startDate.getMonth();

  const endYear = endDate.getFullYear();
  const endMonth = endDate.getMonth();

  const yearDiff = endYear - startYear;
  const monthDiff = endMonth - startMonth;

  const totalMonths = yearDiff * 12 + monthDiff;
  const years = Math.floor(totalMonths / 12);
  const months = totalMonths % 12;

  return { years, months };
}

export const handleTextToSpeech = (text) => {
  const speechSynthesis = window.speechSynthesis;
  const utterance = new SpeechSynthesisUtterance(text);
  //  utterance.voice = ...; // Set a specific voice if needed
utterance.rate = 1;  // Set the rate (speed of speech) if needed
utterance.pitch = 1; // Set the pitch if needed
  return speechSynthesis.speak(utterance);
}; 