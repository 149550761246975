import React, { useState, lazy, Suspense, useEffect } from "react";
import "./navbar.scss";
import { AiOutlineHome, AiFillProject } from "react-icons/ai";
import { RxAvatar } from "react-icons/rx";
import Home from "../home/home";
import Loader from "../loader/Loader";
import About from "../about/about";
import SEO from "../../SEO";

const Projects = lazy(() => import("../projects/project"));

// Preload the Projects component
const preloadProjects = () => {
  import("../projects/project");
};

const NavLink = ({ text, icon, index, activeIndex, handleLinkClick }) => (
  <li className={`list ${activeIndex === index ? "active" : ""}`}>
    <div className="linkclass" onClick={() => handleLinkClick(index)}>
      <span className="text">{text}</span>
      <span className="icon">{icon}</span>
    </div>
  </li>
);

const links = [
  { text: "Home", icon: <AiOutlineHome size={20} /> },
  { text: "About", icon: <RxAvatar size={20} /> },
  { text: "Projects", icon: <AiFillProject size={20} /> },
];

export default function Navbar() {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleLinkClick = (index) => {
    setActiveIndex(index);
  };

  const getSEOProps = () => {
    switch (activeIndex) {
      case 0:
        return {
          title: "Home - My Portfolio",
          description:
            "Welcome to my portfolio. I am a creative MERN stack developer. Explore my projects, achievements, and skills. Contact me for collaboration.",
          keywords:
            "MERN stack developer, web developer, portfolio, JavaScript, MongoDB, Express.js, React, Node.js, web development projects,development, mern, sid, harth, home, resume, about, blog, project, achievements,poumki,tcs,AWS,Jenkins,devops",
        };
      case 1:
        return {
          title: "About Me - My Portfolio",
          description:
            "Learn more about me, a creative MERN stack developer with experience in various web development projects.",
          keywords:
            "about me, MERN stack developer, web developer, portfolio, JavaScript, MongoDB, Express.js, React, Node.js, web development projects",
        };
      case 2:
        return {
          title: "Projects - My Portfolio",
          description:
            "Explore my web development projects using the MERN stack. See my achievements and skills in action.",
          keywords:
            "projects, MERN stack developer, web developer, portfolio, JavaScript, MongoDB, Express.js, React, Node.js, web development projects",
        };
      default:
        return {
          title: "Sidharth's - Portfolio",
          description:
            "Welcome to my portfolio! I’m Sidharth, a MERN stack developer with experience at TCS and Poumki Digital LLP. Explore my projects and skills in JavaScript, MongoDB, Express.js, React, Node.js, Jenkins, and SCSS. Discover my achievements and feel free to check out my resume and projects. Let’s connect!",
          keywords:
           "MERN stack developer, web developer, portfolio, JavaScript, MongoDB, Express.js, React, Node.js, web development projects,development, mern, sid, harth, home, resume, about, blog, project, achievements,poumki,tcs,AWS,Jenkins,devops,Siddhartha ",
        };
    }
  };

  useEffect(() => {
    preloadProjects();
  }, []);

  return (
    <>
      <SEO {...getSEOProps()} />
      <div className="Navconatiner">
        <nav className="navigation">
          <ul>
            {links.map((link, index) => (
              <NavLink
                key={index}
                text={link.text}
                icon={link.icon}
                index={index}
                activeIndex={activeIndex}
                handleLinkClick={handleLinkClick}
              />
            ))}
            <div className={`indicator`}></div>
          </ul>
        </nav>
      </div>
      <Suspense fallback={<Loader />}>
        {activeIndex === 0 && <Home />}
        {activeIndex === 1 && <About />}
        {activeIndex === 2 && <Projects />}
      </Suspense>
    </>
  );
}
