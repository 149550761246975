import './App.css';
// import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import { Suspense, lazy } from 'react';
// import Loader from './components/loader/Loader';
import Navbar from './components/navbar/navbar';

// const Home = lazy(() => import("./components/home/home"));
// const About = lazy(() => import("./components/about/about"));
// const Projects = lazy(() => import("./components/projects/project"));
// const Resume = lazy(() => import("./components/resume/resume"));
// const PageNotFound = lazy(() => import("./components/404/PageNotFound"));

function App() {
  return (
    // <Router>
    //   <Suspense fallback={<Loader/>}>
    //     <Routes>
    //       <Route path="/" element={<Home />} />
    //       <Route path="/about" element={<About />} />
    //       <Route path="/projects" element={<Projects />} />
    //       <Route path="/resume" element={<Resume />} />
    //       <Route path="*" element={<PageNotFound />} />
    //     </Routes>
    //   </Suspense>
    // </Router>
    <Navbar/>
  );
}

export default App;
