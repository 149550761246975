import styles from "../home/home.module.scss";
import React, { Suspense, lazy, useState, useMemo } from 'react';
import funnyMessages from "./funnymessage"; // Import an array of funny messages
import emojis from "./emoji"; // Import an array of emojis

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiFillLinkedin, AiFillMail, AiFillGithub } from "react-icons/ai";
import { FaBlogger } from "react-icons/fa";
import Loader from "../loader/Loader";
// import { CgFileDocument } from "react-icons/cg";

import { motion, AnimatePresence } from "framer-motion";

import Lottie from "lottie-react";
import Animationdata from "../../assets/animations/homeanimation.json";
import WavesSVG from "./WavesSVG";
import TypingRevealText from "./Typewriter";



// Lazy load these components
const Game = lazy(() => import("../game/game"));
const Modal = lazy(() => import("../modal/Modal"));
const Chatbot = lazy(() => import("../chatbot/chatbot"));
const Resume = lazy(() => import("../resume/resume"));

export default function Home() {
  const color = "black";
  const [ToastCount, setToastCount] = useState(1);
  const [Showgame, setShowgame] = useState(false);

  const getRandomElement = (array) =>
    array[Math.floor(Math.random() * array.length)];

  const handleImageClick = () => {
    setToastCount((prevCount) => prevCount + 1);
    const randomMessage = getRandomElement(funnyMessages);
    const randomEmoji = getRandomElement(emojis);
    toast(`${randomMessage} ${randomEmoji}`);
    if (ToastCount === 5) {
      setShowgame(true);
      setToastCount(0);
    }
  };
  const handleGameClose = () => {
    setShowgame(false);
  };
  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.4,
        staggerChildren: 0.3,
      },
    },
  };


  const item = useMemo(() => ({
    hidden: { y: 30, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  }), []);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };


  const homeDes = useMemo(
    () => [
      "I am a Full stack developer with expertise in React and Node.js.",
      "I have experience in building scalable, secure and reliable web applications using various frameworks and technologies.",
      "I enjoy solving complex problems and learning new skills.",
      "I am passionate about creating high-quality code that follows best practices and industry standards.",
      " I am always looking for new challenges and opportunities to grow as a developer",
    ],
    []
  );

  const memoizedTypingRevealText = useMemo(
    () => <TypingRevealText lines={homeDes} variants={item} />,
    [homeDes,item]
  );
  return (
    <>
      {Showgame === false ? (
        <div className={styles.container}>
          <AnimatePresence>
            <motion.div
              variants={container}
              initial="hidden"
              animate="visible"
              className={styles.homecontainer}
            >
              <div className={styles.homegrid1}>
                <motion.h1 className={styles.Name} 
                variants={item} 
                aria-label="Sidharth">
                  Hey,I'm <span>Sid</span>harth
                </motion.h1>
                {memoizedTypingRevealText}
                {/* <motion.h1 className={styles.des} variants={item}>
                {homeDes}
                </motion.h1> */}

                <motion.div className={styles.resume}>
                  <motion.div
                    className={styles.cvButtonContainer}
                    variants={item}
                  >
                    <motion.button
                      whileHover={{ scale: 1.1 }}
                      whiletap={{ scale: 0.6 }}
                      className={styles.cvButton}
                      onClick={openModal}
                    >
                      Resume
                    </motion.button>
                  </motion.div>
                  <Suspense fallback={<Loader/>}>
                    <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                      <Resume />
                    </Modal>
                  </Suspense>
                </motion.div>
                <div className={styles.contact}>
                  <motion.a
                    variants={item}
                    whileHover={{ scale: 1.2 }}
                    whiletap={{
                      scale: 0.8,
                      borderRadius: "100%",
                    }}
                    href="mailto: sidharthe38943@gmail.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Email Sidharth"
                  >
                    <AiFillMail
                      className="rounded-full"
                      size={50}
                      color={color}
                    />
                  </motion.a>
                  <motion.a
                    variants={item}
                    whileHover={{ scale: 1.2 }}
                    whiletap={{
                      scale: 0.8,
                      borderRadius: "100%",
                    }}
                    href="https://www.linkedin.com/public-profile/settings?trk=d_flagship3_profile_self_view_public_profile"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="LinkedIn Sidharth"
                  >
                    <AiFillLinkedin
                      className="rounded-full"
                      size={50}
                      color={color}
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  </motion.a>
                  <motion.a
                    whileHover={{ scale: 1.2 }}
                    whiletap={{
                      scale: 0.8,
                      borderRadius: "100%",
                    }}
                    variants={item}
                    href="https://github.com/Sidharth-e"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Github Sidharth"
                  >
                    <AiFillGithub
                      className="rounded-full"
                      size={50}
                      color={color}
                    />
                  </motion.a>
                  <motion.a
                    whileHover={{ scale: 1.2 }}
                    whiletap={{
                      scale: 0.8,
                      borderRadius: "100%",
                    }}
                    variants={item}
                    href="https://blog.sidharth.app"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Github Sidharth"
                  >
                    <FaBlogger
                      className="rounded-full"
                      size={50}
                      color={color}
                    />
                  </motion.a>
                </div>
              </div>
              <motion.div className={styles.homegrid2}>
                <Lottie
                  style={{ height: 350 }}
                  loop={false}
                  className={styles.avatar}
                  animationData={Animationdata}
                  onClick={handleImageClick}
                />
              </motion.div>
              <ToastContainer autoClose={500} hideProgressBar={true} />
            </motion.div>
          </AnimatePresence>
          <Suspense fallback={<Loader/>}>
            <Chatbot />
          </Suspense>
          <WavesSVG />
        </div>
      ) : (
        <Suspense fallback={<div>Loading Game...</div>}>
        <Game onClose={handleGameClose} />
      </Suspense>
      )}
    </>
  );
}
