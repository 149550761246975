import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import './Typewriter.scss'
const TypingRevealText = ({ lines, delayBetweenLines = 1000, typingSpeed = 60 ,variants}) => {
  const [revealedLines, setRevealedLines] = useState([]);
  const [cursorVisible, setCursorVisible] = useState(true);

  useEffect(() => {
    const revealLines = async () => {
      for (let i = 0; i < lines.length; i++) {
        await new Promise(resolve => setTimeout(resolve, delayBetweenLines));
        const currentLine = lines[i];
        for (let j = 0; j <= currentLine.length; j++) {
          const revealedText = '$ ' + currentLine.slice(0, j);
          setRevealedLines(prevLines => [...prevLines.slice(0, i), revealedText]);
          await new Promise(resolve => setTimeout(resolve, typingSpeed));
        }
      }
    };
  
    const cursorInterval = setInterval(() => {
      setCursorVisible(prevState => !prevState); // Toggle cursor visibility without directly reading prevState
    }, 500); // Cursor blink interval
  
    revealLines();
  
    // Cleanup function to clear any timeouts and intervals if the component unmounts
    return () => {
      clearTimeout(); // Clear any timeouts
      clearInterval(cursorInterval); // Clear the interval
    };
  }, [lines, delayBetweenLines, typingSpeed]);
  

  return (
    <motion.div  className='terminal' variants={variants}>
      {revealedLines.map((line, index) => (
        <div key={index}>
          {line}
          {cursorVisible && index === revealedLines.length - 1 && <span>|</span>} {/* Typing cursor */}
        </div>
      ))}
    </motion.div>
  );
};

export default TypingRevealText;
