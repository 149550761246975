const funnyMessages = [
    "Keep clicking!",
    "You're on fire! 🔥",
    "Can't stop, won't stop! 💪",
    "You're unstoppable!",
    "Click, click, click!",
    "Who needs a break? Not you!",
    "You must have a lot of energy! 🏋️‍♂️",
    "Click like there's no tomorrow!",
    "Wow, you're a clicking machine!",
    "Click faster than the speed of light!",
    "Click me baby, one more time! 🎶",
    "You're clicking like a pro!",
    "The click master has arrived! 👑",
    "You're clicking your way to glory!",
    "Keep calm and click on!",
    "Your clicking skills are out of this world! 🚀",
    "Clicking level: Expert!",
    "Clicking is your superpower!",
    "You're clicking like nobody's business!",
    "Unleash the clicker within!",
    "Clicking has never been this fun!",
    "Clicking madness! 😜",
    "Clicking frenzy! 🌀",
    "Click now, thank me later!",
    "The clickathon has begun!",
    "Clickity-click, that's the spirit!",
    "Clicking to infinity and beyond! 🚀",
    "Click like it's the end of the world!",
    "You're clicking your way to the top!",
  ];
  
  export default funnyMessages;
  