import React from 'react'
import Lottie from 'lottie-react';
import './Loader.scss'
import Animationdata from "../../assets/animations/loader.json";

export default function Loader() {
  return (
    <div className="loader">
    <Lottie animationData={Animationdata} />
  </div>
  )
}
