import React from 'react';
import './WavesSVG.scss'
const WavesSVG = () => (
  <svg className="waves" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
    <defs>
      <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
    </defs>
    <g className="parallax">
    <use href="#gentle-wave" x="48" y="0" fill="rgba(255,238,204,0.7)" /> {/* 70% opacity */}
      <use href="#gentle-wave" x="48" y="3" fill="rgba(255,238,204,0.5)" /> {/* 50% opacity */}
      <use href="#gentle-wave" x="48" y="5" fill="rgba(255,238,204,0.3)" /> {/* 30% opacity */}
      <use href="#gentle-wave" x="48" y="7" fill="#FFEECC" /> {/* Full opacity */}

    </g>
  </svg>
);

export default WavesSVG;
