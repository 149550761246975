import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import './exp.scss';
import { FaCalendar, FaFlag } from 'react-icons/fa';
// import { GiSkills } from 'react-icons/gi';
import { MdWorkHistory } from "react-icons/md";

const Experience = () => {
  const experiences = [
    {
      "company": "KellyOCG",
      "position": "FULL STACK DEVELOPER",
      "duration": "August 2024 - Present",
      "description": "I have recently joined KellyOCG as a Full Stack Developer. In this role, I look forward to applying my skills in developing and maintaining applications across the full technology stack. Although my specific responsibilities are yet to be defined, I am eager to contribute to various projects and collaborate with the team to deliver high-quality solutions. I anticipate working with technologies such as the MERN stack and Azure services to enhance application performance, scalability, and security."
    }
    ,
    {
      "company": "POUMKI DIGITAL LLP",
      "position": "FULL STACK DEVELOPER",
      "duration": "April 2023 - July 2024",
      "description": "In my role as a Full Stack Developer, I handled responsibilities that included developing new features for the e-slides project (both back-end and front-end), contributing to the convergence project, and performing DevOps tasks on AWS. I demonstrated professionalism and commitment in carrying out my duties."
    },    
    {
      company: 'TCS',
      position: 'FULL STACK DEVELOPER',
      duration: 'March 2020 - April 2023',
      description: `I was a developer at TCS Rapid Labs, where we specialized in accelerating 
      innovation. By employing a unique rapid methodology, we swiftly moved from concept to design
       and development, enabling businesses to experience early innovation and expedite decision-making
       . Our core focus was building rapid prototypes utilizing cutting-edge technologies like AI/ML.`,
    },
    {
      company: 'TCS',
      position: 'SRE',
      duration: 'JAN 2020 - March 2020',
      description: `
      I was part of the SRE team, where we embraced a paradigm that prioritized reliability by 
      continuously tracking and improving system performance. Our focus was on reducing incidents 
      in production and implementing targeted prescriptions and procedures to achieve measurable
       performance enhancements. By maximizing reliability and constantly adjusting our workflow,
        we aimed to ensure optimal system functionality and minimize disruptions.
      `,
    },
  ];

  const experienceVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2, // Adjust this threshold as needed
  });

  return (
    <motion.div ref={ref} className="experience">
      <div className="sectiontitle">
        <h2>Work experience</h2>
        <span className="headerLine"></span>
      </div>
      <div className="experience-list">
        {experiences.map((exp, index) => (
          <motion.div
            key={index}
            className={`experience-item ${index % 2 === 0 ? 'left' : 'right'}`}
            variants={experienceVariants}
            initial="hidden"
            animate={inView ? 'visible' : 'hidden'}
          >
            <div className={`circle`}>
              <span>
                <MdWorkHistory size={30} />
              </span>
            </div>
            <div className="exp-deatils">
              <p className="duration">
                <FaCalendar />
                <span>                {exp.duration}</span>
              </p>
              <h3>{exp.position}</h3>
              <span className="headerLine"></span>
              <p className='company'>
                <FaFlag />
                <span>
                {exp.company}</span>
              </p>
              <p className='description'>{exp.description}</p>
            </div>
          </motion.div>
        ))}
      </div>
    </motion.div>
  );
};

export default Experience;

