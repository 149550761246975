import React from "react";
import {
  FaReact,
  FaHtml5,
  FaPython,
  FaNodeJs,
  FaAws,
  FaDatabase,
  FaJenkins,
  FaCss3Alt,
  FaLinux,
  FaDocker,
  FaGit,
} from "react-icons/fa"; // Import icons from react-icons
import { IoLogoJavascript } from "react-icons/io";
import { BsFiletypeScss, BsFiletypeJson } from "react-icons/bs";
import { BiLogoFlask } from "react-icons/bi";
import "./skills.scss";
import { motion, AnimatePresence } from "framer-motion";

const Skills = () => {
  // Array of objects representing icons and their names
  const icons = [
    { icon: <FaHtml5 />, name: "HTML5" },
    { icon: <FaCss3Alt />, name: "CSS" },
    { icon: <IoLogoJavascript />, name: "JS" },
    { icon: <FaReact />, name: "React" },
    { icon: <FaNodeJs />, name: "Node" },
    { icon: <FaDatabase />, name: "MongoDB" },
    { icon: <BsFiletypeScss />, name: "SCSS" },
    { icon: <FaPython />, name: "Python" },
    { icon: <BsFiletypeJson />, name: "Json" },
    { icon: <FaAws />, name: "AWS" },
    { icon: <FaLinux />, name: "Linux" },
    { icon: <FaDocker />, name: "Docker" },
    { icon: <FaJenkins />, name: "Jenkins" },
    { icon: <FaGit />, name: "Git" },
    { icon: <BiLogoFlask />, name: "Flask" },

  ];
  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.1,
      },
    },
  };

  const itemvariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };
  return (
    <AnimatePresence>
      <motion.div
        variants={container}
        initial="hidden"
        animate="visible"
        className="skills"
      >
        <motion.div>
          <motion.div className="sectiontitle">
            <motion.h2>Skills</motion.h2>
            <motion.span className="headerLine"></motion.span>
          </motion.div>
          <motion.div className="icon-grid">
            {icons.map((item, index) => (
              
              <motion.div
              variants={itemvariants}
                whileHover={{ scale: 1.1}}
                whiletap={{
                  scale: 0.8,
                }}
                className="icon"
                key={index}
              >
                {item.icon}
                <span >{item.name}</span>
              </motion.div>
            ))}
          </motion.div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default Skills;

