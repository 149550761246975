// import Navbar from "../navbar/navbar";
import EXP from "./work exp/exp";
import Skills from "./skills/skills";
import styles from "./about.module.scss";
import { motion, AnimatePresence } from "framer-motion";
import { useInView } from "react-intersection-observer";
import {getYearMonthDifference} from '../../utlis/util'


import Lottie from "lottie-react";
import Animationdata from "../../assets/animations/about-animation.json";
export default function About() {
  const date = new Date();
  const experience = getYearMonthDifference(date);
  const [ref] = useInView({
    threshold: 0.2,
  });
  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.3,
      },
    },
  };

  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };
  return (
    <>
      <div className={styles.container}>
        <AnimatePresence>
          <div className={styles.about}>
            <motion.div
              variants={container}
              initial="hidden"
              animate="visible"
              className={styles.container}
            >
              {/* <motion.h1 ref={ref} variants={item} className={styles.title}>
              About Me
            </motion.h1> */}
              <div className={styles.aboutcontainer}>
                  <Lottie
                    style={{ height: 250 }}
                    animationData={Animationdata}
                  />
                <motion.p
                  ref={ref}
                  variants={item}
                  className={styles.description}
                >
                  I am an innovative tech mind with{" "}
                  <strong style={{ fontSize: 18 }}>
                    {" "}
                    {experience.years}.{experience.months} years
                  </strong>{" "}
                  of experience working as a developer. Throughout my career, I
                  have gained valuable expertise in working with a diverse range
                  of technology and software solutions, as well as managing
                  databases. As a developer, I have always been driven by the
                  challenge of solving complex problems. My experience has
                  equipped me with the skills to diagnose issues efficiently and
                  develop effective solutions. I take pride in my ability to
                  think outside the box and come up with unique ideas that have
                  resulted in successful contributions to projects I've been
                  involved in.
                </motion.p>
              </div>
            </motion.div>
          </div>
        </AnimatePresence>
        <Skills />
        <EXP />
      </div>
    </>
  );
}

